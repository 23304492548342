import React from 'react';
import { useState, useEffect } from 'react';
import './encuestas.css';

export const Quejas = () => {

    const URL = 'https://encuestas-api-2023.herokuapp.com/api/pqr';
    const [articulos, setArticulos] = useState([]);
    const [editar, setEditar] = useState(0);

    useEffect(() => {

        getFetch();

    });

    const getFetch = async () => {

        const res = await fetch(URL + '/pqrs', {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('jwt'),
            }
        });
        const resolve = await res.json();

        setArticulos(resolve.pqrs);

    }

    const getFetchUpdate = async (objeto, articuloId) => {


        try {
            const res = await fetch(URL + '/update/' + articuloId, {
                method: 'PUT',
                body: JSON.stringify(objeto),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('jwt'),
                }
            });

            const resolve = await res.json();


            if (resolve.status == 'success') {
                alert(resolve.message);
                document.querySelector('.form-article').reset();
                setEditar(0);
            } else {
                alert(resolve.message);
            }

        } catch (error) {
            console.log(error);
        }

    }

    const resolve = async (articuloId) => {

        setEditar(articuloId);

    }

    const cerrar = (e) => {

        setEditar(0);
    }

    const enviar = (e, articuloId) => {
        e.preventDefault();

        const { target } = e;
        const formData = new FormData(target);

        let objeto = {};

        for (let [name, value] of formData) {
            objeto[name] = value;
        }

        getFetchUpdate(objeto, articuloId);
    }

    return (
        <div className='article-items'>{articulos.length >= 1 ?
            articulos.map((articulo) => {
                return <section >
                    <article className='articulo-item' key={articulo._id}>
                        <h2>Datos del cliente</h2>
                        <p>Cliente: {articulo.client}</p>
                        <p>Direccion: {articulo.address}</p>
                        <p>Telefono: {articulo.phone}</p>
                        <p>Quién reporta: {articulo.name}</p>
                        <h2>Queja, reclamo, sugerencia o felicitación.</h2>
                        <p>Comentario: {articulo.description}</p>
                        <p>Validación de la queja o reclamo: {articulo.validation}</p>
                        <p>Solución inmediata: {articulo.solution}</p>
                        <p>Solicita acción correctiva: {articulo.action}</p>
                        <p>Seguimiento: {articulo.follow}</p>
                        <p>Fecha: {articulo.created_at.split('T')[0]}</p>
                        {editar == articulo._id && (<form onSubmit={(e) => {enviar(e, articulo._id)}} className='form-article'>
                            <div>
                                <label htmlFor='validation'>Validación de la queja o reclamo:</label>
                                <textarea name='validation' />
                            </div>

                            <div>
                                <label htmlFor='solution'>Solución inmediata:</label>
                                <textarea name='solution' />
                            </div>

                            <div>
                                <label htmlFor='action'>Solicita acción correctiva:</label>
                                SI<input type='radio' name='action' value='Si' />
                                NO<input type='radio' name='action' value='No' />
                            </div>

                            <div>
                                <label htmlFor='follow'>Seguimiento:</label>
                                <textarea name='follow' />
                            </div>
                            <button>Solucionar</button>
                            <button onClick={cerrar}>Cerrar</button>
                        </form>)}
                    </article>
                    <button onClick={() => { resolve(articulo._id) }} className='boton-delete'>Dar solución</button>
                </section>
            }) :
            'Cargando...'}</div>
    );
}