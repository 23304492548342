import React from 'react';
import './services.css';
import { FaCarSide } from 'react-icons/fa';
import { FaMotorcycle } from 'react-icons/fa';
import { FaTruckMoving } from 'react-icons/fa';
import { FaTaxi } from 'react-icons/fa';
import { FaChild } from 'react-icons/fa';
import portada from '../../assets/img/cars/teaching.jpeg';

export const Services = () => {
    return (
        <div className='page'>
            <img className='portada' src={portada} alt="portada" />
            <div className='separator'>
                <h1>Nuestros servicios</h1>
            </div>
            <section>
                <article className='section'>
                    <h1 className='title'>Qué cursos de conduccion ofrecemos?</h1>
                    <p className='description'>
                        En los profesionales te capacitamos en las siguientes categorias
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Categoria A2 <FaMotorcycle className='item' /></h1>
                    <p className='description'>
                        Para conducir motocicletas, motociclos, mototriciclo con motor
                        de cilindraje mayor a 125 cc.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Categoria B1 <FaCarSide className='item' /> </h1>
                    <p className='description'>
                        Para conducir automoviles, motocarros, cuatrimotos, camperos,
                        camionetas, microbuses de servicio particular.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Categoria C1 <FaTaxi className='item' /></h1>
                    <p className='description'>
                        Para conducir automoviles, camperos, camionetas y
                        microbuses de sericio público.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Categoria C2 <FaTruckMoving className='item' /></h1>
                    <p className='description'>
                        Para conducir camiones rígidos, busetas y buses de servicio
                        público.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Perfeccionamiento <FaChild className='item' /></h1>
                    <p className='description'>
                        Perfeccionamos tu manera de conducir, trabajamos sobre tus falencias 
                        y las mejoramos, para que tu conducción sea totalmente idónea.
                    </p>
                </article>
            </section>
        </div>
    );
}