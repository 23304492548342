import React from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import { FaCarAlt } from 'react-icons/fa';
import { FaHome } from 'react-icons/fa';
import { FaHistory } from 'react-icons/fa';
import { FaUserGraduate } from 'react-icons/fa';

export const Home = () => {
    return (
        <>
            <div className='teach'>

                <div className='info'>
                    <ul>
                        <li>
                            <Link to="/register">Ingresar</Link>
                        </li>

                        <li>
                            <Link to="/contact">Contacto</Link>
                        </li>
                    </ul>
                    <div className='slogan'>
                        <h2>La disciplina direcciona nuestro compromiso</h2>
                    </div>
                </div>
            </div>

            <div className='separator'>
                <h1>Información</h1>
            </div>
            <section>
                <article className='section'>
                    <h1 className='title'>Por qué elegir los profesionales?</h1>
                    <p className='description'>
                        Contamos con las mejores instalaciones y con automoviles exclusivos,
                        para que tu proceso de aprendizaje sea mucho mas cómodo y eficiente.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'><FaCarAlt className='item'/></h1>
                    <p className='description'>
                        Contamos con los mejores vehiculos, exclusivos y cómodos.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'><FaHome className='item'/></h1>
                    <p className='description'>
                        Contamos con las mejores instalaciones para tu 
                        proceso de aprendizaje.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'><FaHistory className='item'/></h1>
                    <p className='description'>
                        Contamos con muchos años de experiencia en el area.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'><FaUserGraduate className='item'/></h1>
                    <p className='description'>
                        Contamos con el mejor personal, capacitado para darte la mejor atencion.
                    </p>
                </article>
            </section>
        </>
    );
}