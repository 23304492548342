import React from 'react';
import Mazda from '../../assets/img/cars/mazda2.jpeg';
import './ingreso.css';

export const Ingreso = () => {

    const URL = 'https://encuestas-api-2023.herokuapp.com/api/ingreso';

    const enviar = (e) => {
        e.preventDefault();

        const { target } = e;

        const formData = new FormData(target);

        let objeto = {};

        for (let [name, value] of formData) {
            objeto[name] = value;
        }

        getFetch(objeto);

    }

    const getFetch = async (objeto) => {
        try {
            const res = await fetch(URL + '/save', {
                method: 'POST',
                body: JSON.stringify(objeto),
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const resolve = await res.json();

            if (resolve.status == 'success') {
                document.querySelector('.formulario').reset();

                alert(resolve.message);
            } else {
                alert(resolve.message);
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='page'>
            <img className='portada' alt='car' src={Mazda} />
            <div className='separator'>
                <h1>Califícanos</h1>
            </div>
            <form className='formulario' onSubmit={enviar}>
                <h2>El nombre y el documento, son campos totalmente opcionales.</h2>
                <div className='form-group'>
                    <label htmlFor='name'>Nombre completo</label>
                    <input type='text' name='name' placeholder='Nombre' />
                </div>

                <div className='form-group'>
                    <label htmlFor='document'>Numero de documento</label>
                    <input type='text' name='document' placeholder='Documento' />
                </div>
                <h2>Encuesta de satisfaccion al cliente</h2>
                <p>
                    Para nuestra institución es muy importante conocer su grado de satisfacción
                    frente a los servicios que prestamos. Evalúe los procesos institucionales
                    en los siguientes aspectos y asigne una calificación de 1 a 5, seleccione cada
                    casilla, teniendo en cuenta que 1 es el más bajo(No satisfecho) y 5 el más alto
                    (Plenamente satisfecho).
                </p>
                <h3>
                    Procesos directivos y administrativos.
                </h3>

                <div className='form-group'>
                    <label htmlFor='uno'>1. Amabilidad y disponibilidad de las personas encargadas
                        (Director y secretarias).
                    </label>
                    <div className='check-group'>
                        1.<input type='radio' name='uno' value='1' />
                        2.<input type='radio' name='uno' value='2' />
                        3.<input type='radio' name='uno' value='3' />
                        4.<input type='radio' name='uno' value='4' />
                        5.<input type='radio' name='uno' value='5' />
                    </div>
                </div>

                <div className='form-group'>
                    <label htmlFor='dos'>2. Oportunidad y agilidad en la atención de sus solicitudes.
                    </label>
                    <div className='check-group'>
                        1.<input type='radio' name='dos' value='1' />
                        2.<input type='radio' name='dos' value='2' />
                        3.<input type='radio' name='dos' value='3' />
                        4.<input type='radio' name='dos' value='4' />
                        5.<input type='radio' name='dos' value='5' />
                    </div>
                </div>

                <div className='form-group'>
                    <label htmlFor='tres'>3. Claridad en la información entregada.
                    </label>
                    <div className='check-group'>
                        1.<input type='radio' name='tres' value='1' />
                        2.<input type='radio' name='tres' value='2' />
                        3.<input type='radio' name='tres' value='3' />
                        4.<input type='radio' name='tres' value='4' />
                        5.<input type='radio' name='tres' value='5' />
                    </div>
                </div>

                <h3>
                    Procesos pedagógicos.
                </h3>

                <div className='form-group'>
                    <label htmlFor='cuatro'>1. Amabilidad y disponibilidad de las personas
                        encargadas (Instrucciones teóricas y prácticas).
                    </label>
                    <div className='check-group'>
                        1.<input type='radio' name='cuatro' value='1' />
                        2.<input type='radio' name='cuatro' value='2' />
                        3.<input type='radio' name='cuatro' value='3' />
                        4.<input type='radio' name='cuatro' value='4' />
                        5.<input type='radio' name='cuatro' value='5' />
                    </div>
                </div>

                <div className='form-group'>
                    <label htmlFor='cinco'>2. Oportunidad y agilidad en la atención de sus solicitudes.
                    </label>
                    <div className='check-group'>
                        1.<input type='radio' name='cinco' value='1' />
                        2.<input type='radio' name='cinco' value='2' />
                        3.<input type='radio' name='cinco' value='3' />
                        4.<input type='radio' name='cinco' value='4' />
                        5.<input type='radio' name='cinco' value='5' />
                    </div>
                </div>

                <div className='form-group'>
                    <label htmlFor='seis'>3. Claridad en la información entregada.
                    </label>
                    <div className='check-group'>
                        1.<input type='radio' name='seis' value='1' />
                        2.<input type='radio' name='seis' value='2' />
                        3.<input type='radio' name='seis' value='3' />
                        4.<input type='radio' name='seis' value='4' />
                        5.<input type='radio' name='seis' value='5' />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='sugerencias'>Sugerencias y comentarios:</label>
                    <textarea name='sugerencias' />
                </div>
                <button>Enviar</button>
            </form>
        </div>
    );
}