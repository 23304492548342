import React from 'react';
import './contact.css';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import portada from '../../assets/img/cars/classroom2.jpeg';

export const Contact = () => {

    const enviar = (e) => {
        e.preventDefault();

        document.querySelector('.contact').reset();

        alert('Tu correo fué enviado con exito');
    }

    return (
        <div className='container'>
            <div className='content'>
                <img className='portada' src={portada} alt="portada" />
            </div>
            <div className='separator'>
                <h1>Contacto</h1>
            </div>
            <div className='contacto'>
                <form className='contact' onSubmit={enviar}>
                    <input type="text" placeholder='Nombre' />
                    <input type="text" placeholder='Apellidos' />
                    <input type="email" placeholder='Email' />
                    <textarea placeholder='Motivo del contacto'></textarea>

                    <input type="submit" value="Enviar" />
                </form>
                <div className='social'>
                    <h1 className='title'>Siguenos en</h1>
                    <div className='items'>
                        <a target="_blank" href='https://m.facebook.com/people/CEA-Los-profesionales/100063967206506/'><FaFacebookF className='item' /></a>
                        <a target="_blank" href='https://www.instagram.com/cea.losprofesionales/?hl=es'><FaInstagram className='item' /></a>
                        <a target="_blank" href='https://wa.me/573006686741?text=Hola,%20quiero%20saber%20mas%20sobre%20tus%20servicios'><FaWhatsapp className='item' /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}