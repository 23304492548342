import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { Home } from '../components/home/Home';
import { About } from '../components/about/About';
import { Services } from '../components/services/Services';
import { Contact } from '../components/contact/Contact';
import { Header } from '../layout/header';
import { Aside } from '../layout/Aside';
import { Footer } from "../layout/Footer";
import { Ingreso } from '../components/check/Ingreso';
import { Pqr } from "../components/pqr.js/Pqr";
import { Salida } from "../components/check/Salida";
import { Login } from "../components/login/Login";
import { IngresoEncuesta } from '../components/encuestas/IngresoEncuesta';
import { Quejas } from '../components/encuestas/Quejas';
import { SalidaEncuesta } from '../components/encuestas/SalidaEncuesta';

export const MyRoutes = () => {
    return (
        <BrowserRouter>

            <Header />

            <Routes>

                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/check" element={<Ingreso />} />
                <Route path="/pqr" element={<Pqr />} />
                <Route path="/salida" element={<Salida />} />
                <Route path="/register/*" element={<Login />} >
                    <Route path="" element={<Navigate to='/register/encuesta-ingreso' />} />
                    <Route path="encuesta-ingreso" element={<IngresoEncuesta />} />
                    <Route path="encuesta-salida" element={<SalidaEncuesta />} />
                    <Route path="encuesta-quejas" element={<Quejas />} />
                </Route>

                <Route path="*" element={<Navigate to="/home" />} />

            </Routes>

            <Aside />

            <Footer />


        </BrowserRouter>
    );
}