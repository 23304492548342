import React from 'react';
import './ingreso.css';
import mazda from '../../assets/img/cars/mazda-side.jpeg';

export const Salida = () => {

    const URL = 'https://encuestas-api-2023.herokuapp.com/api/salida';

    const enviar = (e) => {
        e.preventDefault();

        const { target } = e;
        const formData = new FormData(target);
        const objeto = {};

        for (let [name, value] of formData) {
            objeto[name] = value;
        }

        getFetch(objeto);
    }

    const getFetch = async (objeto) => {
        const res = await fetch(URL + '/save', {
            method: 'POST',
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const resolve = await res.json();

        if (resolve.status == 'success') {
            alert(resolve.message);

            document.querySelector('.formulario').reset();
        } else {
            alert(resolve.message);
        }

    }

    return (
        <div className='page'>
            <img className='portada' src={mazda} alt='car' />
            <div className='separator'>
                <h1>Califica tu experiencia</h1>
            </div>
            <form className='formulario' onSubmit={enviar}>
                <h2>El nombre y el documento, son campos totalmente opcionales.</h2>
                <div className='form-group'>
                    <label htmlFor='name'>Nombre completo</label>
                    <input type='text' name='name' placeholder='Nombre' />
                </div>

                <div className='form-group'>
                    <label htmlFor='document'>Numero de documento</label>
                    <input type='text' name='document' placeholder='Documento' />
                </div>
                <h2>Encuesta a los usuarios del servicio.</h2>
                <p>
                    La calificación se obtiene, teniendo en cuenta: E(Excelente), B(Bueno),
                    R(Regular), M(Malo), N/A(No aplica, en caso que no se haya presentado El
                    evento).
                </p>
                <h2>Preguntas</h2>
                <div className='form-group'>
                    <label htmlFor='uno'>1. Como usuario, conozco las posibilidades que me ofrece el servicio.</label>
                    <select name='uno'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='dos'>2. El personal cuenta con recursos materiales suficientes, para llevar a cabo
                        su trabajo.
                    </label>
                    <select name='dos'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='tres'>3. El personal dispone de tecnología adecuada para realizar su trabajo
                        (Equipos informáticos y de otro tipo).
                    </label>
                    <select name='tres'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='cuatro'>4. El personal dispone de los medios adecuados de comunicación con
                        otros servicios para realizar más facilmente su labor.
                    </label>
                    <select name='cuatro'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='cinco'>5. El servicio cuenta con suficiente espacio y mobiliario para acoger a todos
                        los usuarios de forma cómoda.
                    </label>
                    <select name='cinco'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='seis'>6. El servicio realiza labor esperada correctamente y con seguridad.
                    </label>
                    <select name='seis'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='siete'>7. El personal se muestra dispuesto a ayudar a los usuarios.
                    </label>
                    <select name='siete'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='ocho'>8. El servicio dá respuesta rápida a las necesidades y problemas de los usuarios.
                    </label>
                    <select name='ocho'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='nueve'>9. Cuando acudo al servicio, sé que encontraré las mejores soluciones.
                    </label>
                    <select name='nueve'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='diez'>10. El personal está totalmente cualificado para las tareas que tiene que realizar.
                    </label>
                    <select name='diez'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='once'>11. EL trato del personal con los usuarios, es considerado y amable.
                    </label>
                    <select name='once'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='doce'>12. El horario del servicio asegura, que se pueda acudir a él, siempre que se
                        necesita.
                    </label>
                    <select name='doce'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='trece'>13. El servicio informa de una manera clara y comprensible a los usuarios.
                    </label>
                    <select name='trece'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='catorce'>14. El servicio conoce las necesidades de los usuarios.
                    </label>
                    <select name='catorce'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='quince'>15. El servicio recoge de forma adecuada las quejas y sugerencias de los usuarios.
                    </label>
                    <select name='quince'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='dieciseis'>16. El servicio se adapta perfectamente, a mi necesidades como usuario.
                    </label>
                    <select name='dieciseis'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='diecisiete'>17. El servicio ha solucionado satisfactoriamente mis demandas en ocasiones.
                    </label>
                    <select name='diecisiete'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='dieciocho'>18. Cree que la opinion que tiene otros usuarios del servicio, es buena.
                    </label>
                    <select name='dieciocho'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='diecinueve'>19. He observado mejoras en el funcionamiento general del servicio en mis
                        distintas visitas del mismo.
                    </label>
                    <select name='diecinueve'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='veinte'>20. He observado mejoras en el funcionamiento general del servicio en
                        mis distintas consultas(Telefono) al mismo.
                    </label>
                    <select name='veinte'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <label htmlFor='veintiuno'>21. En general me encuentro satisfecho con este servicio.
                    </label>
                    <select name='veintiuno'>
                        <option>Excelente</option>
                        <option>Bueno</option>
                        <option>Regular</option>
                        <option>Malo</option>
                    </select>
                </div>

                <div className='form-group'>
                    <p>
                        Si desea, señale las observaciones o aclaraciones que crea oportunas con respecto a las
                        preguntas y/o sugerencias para mejorar el servicio.
                    </p>
                    <label htmlFor='sugerencias'>Comentarios y sugerencias:
                    </label>
                    <textarea name='sugerencias' />
                </div>

                <button>Enviar</button>
            </form>
        </div>
    );
}