import React from 'react';
import './about.css';
import { FaMedal } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { FaHistory } from 'react-icons/fa';
import portada from '../../assets/img/cars/mazda-front.jpeg';
import teachers from '../../assets/img/cars/portada.jpeg';
import profesor from '../../assets/img/cars/car-lili.jpeg'
import coach from '../../assets/img/cars/car-nata.jpeg';
import tutor from '../../assets/img/cars/car-eliza.jpeg';
import motoDriver from '../../assets/img/cars/ayco-driver.jpeg';
import vanDriver from '../../assets/img/cars/camion-driver.jpeg';

export const About = () => {
    return (
        <div className='page'>
            <img className='portada' src={portada} alt="portada" />
            <div className='separator'>
                <h1>Quienes somos?</h1>
            </div>
            <section className='container'>
                <article className='section'>
                    <h1 className='title'>Misión <FaMedal className='item' /></h1>
                    <p className='description'>
                        El centro de enseñanza automovilística los profesionales, es un 
                        centro académico que tiene como misión satisfacer las necesidades
                        y expectativas de nuestros alumnos en la capacitación como conductores,
                        se encuentra comprometido con el desarrollo de la persona humana y con
                        la realización de los valores esenciales mediante el cultivo del 
                        conocimiento racional, con miras a la formación integral. Para ello
                        cuenta con personal profesional e idóneo y con una infraestructura y 
                        parque automotor moderno, acatando los más elevados principios éticos
                        y de respeto del individuo, todo enfocado en las competencias laborales.
                     </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Visión <FaEye className='item' /></h1>
                    <p className='description'>
                        Ser un centro de enseñanza automovilística acreditado, de excelente
                        reputación e imagen, en el medio cultural, económico, social y con una 
                        alta experiencia académica, formando conductores altamente calificados, y
                        enfocados en un excelente desempeño.
                    </p>
                </article>

                <article className='section'>
                    <h1 className='title'>Política de calidad <FaHistory className='item' /></h1>
                    <p className='description'>
                        Nuestra política de calidad está enmarcada en la prestación de servicios
                        y de formación, capacitación y evaluación en competencias, necesarios Para
                        expedir certificaciones personales, asegurando la satisfacción de nuestros
                        aprendices, cliente y el cumplimiento de los requisitos legales establecidos.
                        Prestar un excelente servicio con un mejoramiento continuo de nuestro sistema
                        de gestión, reconociendo la importancia de las sugerencias, clientes, proveedores
                        y demás partes interesadas, garantizando la gestión de recursos necesarios y la
                        competencia de nuestro personal, asegurando un servicio de calidad y oportunidad.

                        El logro, mantenimiento y mejora del sistema de gestión del CEA se visualiza a través
                        del establecimiento, revisión y consecución permanente de los objetivos de calidad, los
                        cuales al igual que esta política, son comunicados, entendidos y revisados periódicamente
                        para su continua adecuación dentro de la organización.
                    </p>
                </article>
            </section>

            <div>
                <div className='vehiculos'>
                    <h1>Nuestro equipo de trabajo</h1>
                    <h2>
                        Contamos con personal totalmente calificado para prestarte la mejor
                        atención.
                    </h2>
                </div>
                <img className='car' src={teachers} alt="schoole" />
                <img className='car' src={profesor} alt="schoole" />
                <img className='car' src={coach} alt="schoole" />
                <img className='car' src={tutor} alt="schoole" />
                <img className='car' src={motoDriver} alt="schoole" />
                <img className='car' src={vanDriver} alt="schoole" />
            </div>
        </div>
    );
}