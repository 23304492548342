import React from 'react';
import { useState, useEffect } from 'react';

export const SalidaEncuesta = () => {

    const URL = 'https://encuestas-api-2023.herokuapp.com/api/salida';
    const [articulos, setArticulos] = useState([]);

    useEffect(() => {

        getFetch();

    }, []);

    const getFetch = async () => {
        const res = await fetch(URL + '/salidas', {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('jwt'),
            }
        });
        const resolve = await res.json();

        setArticulos(resolve.salidas);
    }

    const remove = async (articuloId) => {

        let resultado = window.confirm('Quieres eliminar el documento?');

        if (resultado) {
            const res = await fetch(URL + '/remove/' + articuloId, {
                method: 'DELETE',
                headers: {
                    'Authorization': localStorage.getItem('jwt'),
                }
            });
            const resolve = await res.json();
    
            alert(resolve.message);
    
            getFetch();
        } else {
            alert('No se eliminó ningún documento');
        }

    }

    return (
        <div className='article-items'>{articulos.length >= 1 ? 
            articulos.map((articulo) => {
                return <section >
                    <article className='articulo-item' key={articulo._id}>
                        <h2>Nombre: <p>{articulo.name}</p></h2>
                        <h2>Documento: <p>{articulo.document}</p></h2>
                        <h3>Calificaciones:</h3>
                        <h2> Procesos directivos y administrativos.</h2>
                        <p>1. Como usuario, conozco las posibilidades que me ofrece el servicio: {articulo.uno}</p>
                        <p>2. El personal cuenta con recursos materiales suficientes, para llevar a cabo
                        su trabajo: {articulo.dos}</p>
                        <p>3. El personal dispone de tecnología adecuada para realizar su trabajo
                        (Equipos informáticos y de otro tipo): {articulo.tres}</p>
                        <p>4. El personal dispone de los medios adecuados de comunicación con
                        otros servicios para realizar más facilmente su labor: {articulo.cuatro}</p>
                        <p>5. El servicio cuenta con suficiente espacio y mobiliario para acoger a todos
                        los usuarios de forma cómoda: {articulo.cinco}</p>
                        <p>6. El servicio realiza labor esperada correctamente y con seguridad: {articulo.seis}</p>
                        <p>7. El personal se muestra dispuesto a ayudar a los usuarios: {articulo.siete}</p>
                        <p>8. El servicio dá respuesta rápida a las necesidades y problemas de los usuarios: {articulo.ocho}</p>
                        <p>9. Cuando acudo al servicio, sé que encontraré las mejores soluciones: {articulo.nueve}</p>
                        <p>10. El personal está totalmente cualificado para las tareas que tiene que realizar: {articulo.diez}</p>
                        <p>11. EL trato del personal con los usuarios, es considerado y amable: {articulo.once}</p>
                        <p>12. El horario del servicio asegura, que se pueda acudir a él, siempre que se
                        necesita: {articulo.doce}</p>
                        <p>13. El servicio informa de una manera clara y comprensible a los usuarios: {articulo.trece}</p>
                        <p>14. El servicio conoce las necesidades de los usuarios: {articulo.catorce}</p>
                        <p>15. El servicio recoge de forma adecuada las quejas y sugerencias de los usuarios: {articulo.quince}</p>
                        <p>16. El servicio se adapta perfectamente, a mi necesidades como usuario: {articulo.dieciseis}</p>
                        <p>17. El servicio ha solucionado satisfactoriamente mis demandas en ocasiones: {articulo.diecisiete}</p>
                        <p>18. Cree que la opinion que tiene otros usuarios del servicio, es buena: {articulo.dieciocho}</p>
                        <p>19. He observado mejoras en el funcionamiento general del servicio en mis
                        distintas visitas del mismo: {articulo.diecinueve}</p>
                        <p>20. He observado mejoras en el funcionamiento general del servicio en
                        mis distintas consultas(Telefono) al mismo: {articulo.veinte}</p>
                        <p>21. En general me encuentro satisfecho con este servicio: {articulo.veintiuno}</p>
                        <p>Comentarios y sugerencias: {articulo.sugerencias}</p>
                        <p>Fecha: {articulo.created_at.split('T')[0]}</p>
                    </article>
                    <button onClick={() => {remove(articulo._id)}} className='boton-delete'>Eliminar</button>
                </section>
            }) : 
            'Cargando...'}</div>
    );
}