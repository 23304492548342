import React from 'react';
import { FaClock } from 'react-icons/fa';
import { FaClipboardList } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaCity } from 'react-icons/fa';
import carro2 from '../assets/img/cars/car-side.jpeg';
import carro3 from '../assets/img/cars/moto.jpeg';
import carro4 from '../assets/img/cars/suzuki.jpeg';
import schoole from '../assets/img/cars/salon.jpeg';
import office from '../assets/img/cars/office.jpeg';
import classRoom from '../assets/img/cars/class.jpeg';
import van from '../assets/img/cars/camion.jpeg';
import moto from '../assets/img/cars/ayco.jpeg';
import teaching from '../assets/img/cars/classroom.jpeg';
import mazdaSide from '../assets/img/cars/mazda-side.jpeg';

export const Aside = () => {
    return (
        <div>
            <aside className='content-aside'>
                <section>
                    <article className="articulos">
                        <h1 className='name'><FaClock className='icon' /></h1>
                        <p className='contenido-aside'>Horarios que se adaptan a ti.</p>
                    </article>

                    <article className="articulos">
                        <h1 className='name'><FaClipboardList className='icon' /></h1>
                        <p className='contenido-aside'>Trámites agiles.</p>
                    </article>

                    <article className="articulos">
                        <h1 className='name'><FaCheck className='icon' /></h1>
                        <p className='contenido-aside'>Personal calificado.</p>
                    </article>

                    <article className="articulos">
                        <h1 className='name'><FaCity className='icon' /></h1>
                        <p className='contenido-aside'>
                            Instalaciones adaptadas para tu aprendizaje.
                        </p>
                    </article>
                </section>
            </aside>
            <div className='schedule'>
                <h2>Horarios de atención en la semana</h2>
                <p className='hour'>
                    De lunes a viernes
                    <p><strong>08:00 am - 12:00 pm</strong></p>
                    <p><strong>02:00 pm - 06:00 pm</strong></p>
                </p>
            </div>

            <div className='schedule'>
                <h2>Horarios de atención sabados</h2>
                <p className='hour'>
                    Sabados
                    <p><strong>09:00 am - 12:00 pm</strong></p>
                    <p><strong>03:00 pm - 04:30 pm</strong></p>
                </p>
            </div>

            <div className='schedule'>
                <h2>Horarios Teoria en la semana</h2>
                <p className='hour'>
                    De lunes a viernes
                    <p><strong>02:30 pm - 04:00 pm</strong></p>
                    <p><strong>04:30 pm - 06:00 pm</strong></p>
                    <p><strong>06:30 pm - 08:00 pm</strong></p>
                </p>
            </div>

            <div className='schedule'>
                <h2>Horarios Teoria sabados</h2>
                <p className='hour'>
                    Sabados
                    <p><strong>09:00 am - 10:30 am</strong></p>
                    <p><strong>11:00 am - 12:30 pm</strong></p>
                    <p><strong>03:00 pm - 04:30 pm</strong></p>
                </p>
            </div>

            <div>
                <div className='vehiculos'>
                    <h1>Nuestros vehiculos</h1>
                    <h2>Contamos con vehículos exclusivos para el proceso de tu aprendizaje.</h2>
                </div>
                <img className='car' src={carro2} alt="car" />
                <img className='car' src={carro3} alt="car" />
                <img className='car' src={carro4} alt="car" />
                <img className='car' src={van} alt="car" />
                <img className='car' src={moto} alt="car" />
                <img className='car' src={mazdaSide} alt="car" />
            </div>

            <div>
                <div className='vehiculos'>
                    <h1>Nuestras instalaciones</h1>
                    <h2>
                        Contamos con las mejores instalaciones, comodas y adaptadas para
                        tu proceso de formación.
                    </h2>
                </div>
                <img className='car' src={schoole} alt="schoole" />
                <img className='car' src={office} alt="schoole" />
                <img className='car' src={classRoom} alt="schoole" />
                <img className='car' src={teaching} alt="schoole" />
            </div>
        </div>
    );
}