import React from 'react';
import './pqr.css';
import MazdaSide from '../../assets/img/cars/mazda-side.jpeg';

export const Pqr = () => {

    const URL = 'https://encuestas-api-2023.herokuapp.com/api/pqr';

    const enviar = (e) => {
        e.preventDefault();

        const { target } = e;
        const formData = new FormData(target);

        let objeto = {};

        for (let [name, value] of formData) {
            objeto[name] = value;
        }
        getFetch(objeto);

    }

    const getFetch = async (objeto) => {
        try {
            const res = await fetch(URL + '/save', {
                method: 'POST',
                body: JSON.stringify(objeto),
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const resolve = await res.json();

            if (resolve.status == 'success') {
                document.querySelector('.formulario').reset();

                alert(resolve.message);
            } else {
                alert(resolve.message);
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='page'>
            <img className='portada' src={MazdaSide} alt='car' />
            <div className='separator'>
                <h1>Quejas y reclamos</h1>
            </div>
            <form className='formulario' onSubmit={enviar}>
                <h2>Procedimiento de quejas, reclamos y sugerencias.</h2>
                <h2>Registro de gestión de la calidad. </h2>
                <p>Formato quejas y reclamos.</p>
                <div className='form-group'>
                    <label htmlFor='client'>Cliente/Alumno:</label>
                    <input type='text' name='client' placeholder='Cliente' />
                </div>

                <div className='form-group'>
                    <label htmlFor='address'>Dirección:</label>
                    <input type='text' name='address' placeholder='Dirección' />
                </div>

                <div className='form-group'>
                    <label htmlFor='phone'>Teléfono:</label>
                    <input type='text' name='phone' placeholder='Teléfono' />
                </div>

                <div className='form-group'>
                    <label htmlFor='name'>Quién reporta:</label>
                    <input type='text' name='name' placeholder='Nombre' />
                </div>

                <div className='form-group'>
                    <label htmlFor='description'>Descripción de la queja o reclamo:</label>
                    <textarea name='description' />
                </div>
                <button>Enviar</button>
            </form>
        </div>
    );
}