import './App.css';
import { MyRoutes } from './routes/MyRoutes';

function App() {
  return (
    <MyRoutes className='layout'/>
  );
}

export default App;
