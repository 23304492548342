import React from 'react';
import './login.css';
import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import mazda from '../../assets/img/cars/mazda-side.jpeg';

export const Login = () => {

    const URL = 'https://encuestas-api-2023.herokuapp.com/api/user';
    const [encuestas, setEncuentas] = useState([]);

    const [items, setItems] = useState([]);

    const login = (e) => {
        e.preventDefault();

        const { target } = e;
        const formData = new FormData(target);

        let objeto = {};

        for (let [name, value] of formData) {
            objeto[name] = value;
        }

        getFetch(objeto);
    }

    const getFetch = async (objeto) => {

        try {

            const res = await fetch(URL + '/login', {
                method: 'POST',
                body: JSON.stringify(objeto),
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const resolve = await res.json();

            if (resolve.status == 'success') {
                alert('Has iniciado sesion');

                document.querySelector('.formulario').reset();
                setItems(resolve.jwt);
                localStorage.setItem('jwt', resolve.jwt);
                
            } else {
                alert(resolve.message);
            }

            console.log(resolve);

        } catch (error) {
            console.log(error);
        }

    }

    if (!items.length >= 1) {
        return (
            <div className='page'>
                <img className='portada' alt='car' src={mazda} />
                <div className='separator'>
                    <h1>Registro</h1>
                </div>
                <form className='formulario' onSubmit={login}>
                    <div className='form-group'>
                        <label htmlFor='email'>Correo</label>
                        <input type='email' name='email' placeholder='Correo' />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='password'>Contraseña</label>
                        <input type='password' name='password' placeholder='Contraseña' />
                    </div>
                    <button>Ingresar</button>
                </form>
            </div>
        );
    }

    return (
        <div className='page'>
            <img className='portada' alt='car' src={mazda} />
            <div className='separator'>
                <h1>Resultados encuestas</h1>
            </div>
            <ul className='menu-encuestas'>
                <li>
                    <NavLink to='/register/encuesta-ingreso'>Encuestas ingreso</NavLink>
                </li>
                <li>
                    <NavLink to='/register/encuesta-salida'>Encuestas salida</NavLink>
                </li>
                <li>
                    <NavLink to='/register/encuesta-quejas'>Encuestas quejas</NavLink>
                </li>
            </ul>
            <div className='container-login'>
                <Outlet />
            </div>

        </div>
    );
}