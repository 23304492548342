import React, { useState } from "react";
import logo from '../assets/img/logo.png';
import { NavLink } from "react-router-dom";
import { FaWhatsapp } from 'react-icons/fa';

export const Header = () => {

    const [isOpen, setIsOpen] = useState(false);

    const appearMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <header className="header">

                <div className="logo">
                    <img src={logo} alt='logo' />
                    <h3>"La disciplina direcciona nuestro compromiso"</h3>
                    <div className={`toggle ${isOpen && 'open'}`} onClick={appearMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <p>Menú</p>
                    </div>

                </div>

                <nav className={`menu ${isOpen && 'open'}`}>
                    <ul>
                        <li> <NavLink to="/home" className={({ isActive }) => isActive ? 'active' : ''} onClick={appearMenu}>Inicio</NavLink> </li>

                        <li> <NavLink to="/about" onClick={appearMenu}>Nosotros</NavLink> </li>

                        <li> <NavLink to="/services" onClick={appearMenu}>Servicios</NavLink> </li>

                        <li> <NavLink to="/contact" onClick={appearMenu}>Contacto</NavLink> </li>
                    </ul>
                </nav>

            </header>
            <div className="whats">
                <a href="https://wa.me/573006686741?text=Hola,%20quiero%20saber%20mas%20sobre%20tus%20servicios"><FaWhatsapp className="item-whats" /></a>
            </div>
        </div>
    );
}