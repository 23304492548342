import React from 'react';
import { useState, useEffect } from 'react';
import './encuestas.css';

export const IngresoEncuesta = () => {
    const [articulos, setArticulos] = useState([]);
    const URL = 'https://encuestas-api-2023.herokuapp.com/api/ingreso';

    useEffect(() => {

        getFetch();

    }, []);

    const getFetch = async () => {

        const res = await fetch(URL + '/ingresos', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('jwt'),
            }
        });
        const resolve = await res.json();

        setArticulos(resolve.ingresos);

    }

    const remove = async (articuloId) => {

        let resultado = window.confirm('Quieres eliminar el documento?');

        if (resultado) {
            const res = await fetch(URL + '/remove/' + articuloId, {
                method: 'DELETE',
                headers: {
                    'Authorization': localStorage.getItem('jwt'),
                }
            });
            const resolve = await res.json();
    
            alert(resolve.message);
    
            getFetch();
        } else {
            alert('No se eliminó ningún documento');
        }

    }

    return (
        <div className='article-items'>{articulos.length >= 1 ? 
            articulos.map((articulo) => {
                return <section >
                    <article className='articulo-item' key={articulo._id}>
                        <h2>Nombre: <p>{articulo.name}</p></h2>
                        <h2>Documento: <p>{articulo.document}</p></h2>
                        <h3>Calificaciones:</h3>
                        <h2> Procesos directivos y administrativos.</h2>
                        <p>1. Amabilidad y disponibilidad de las personas encargadas
                        (Director y secretarias): {articulo.uno}</p>
                        <p>2. Oportunidad y agilidad en la atención de sus solicitudes: {articulo.dos}</p>
                        <p>3. Claridad en la información entregada: {articulo.tres}</p>
                        <h2>Procesos pedagógicos</h2>
                        <p>1. Amabilidad y disponibilidad de las personas
                        encargadas (Instrucciones teóricas y prácticas): {articulo.cuatro}</p>
                        <p>2. Oportunidad y agilidad en la atención de sus solicitudes: {articulo.cinco}</p>
                        <p>3. Claridad en la información entregada: {articulo.seis}</p>
                        <p>Sugerencias: {articulo.sugerencias}</p>
                        <p>Fecha: {articulo.created_at.split('T')[0]}</p>
                    </article>
                    <button onClick={() => {remove(articulo._id)}} className='boton-delete'>Eliminar</button>
                </section>
            }) : 
            'Cargando...'}</div>
    );
}