import React from "react";

export const Footer = () => {
    return (
        <div className="footer">
            <footer>
                <h3>Copyright &copy;</h3>
                <p>2023, Diseñado y desarrollado por CEA los Profesionales </p> 
            </footer>
        </div>
    );
}